import { lazy, Suspense } from "react";
import { Routes, Route, Outlet } from "react-router-dom";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import red from "@mui/material/colors/red";

import "./App.css";

import PageTemplate from "./pages/page-template";
import ResponsiveAppBar from "./components/ResponsiveAppBar";
import Login from "./pages/login";
import Home from "./pages/home";

const Sales = lazy(() => import("./pages/sales"));
const Stats = lazy(() => import("./pages/stats"));
const General = lazy(() => import("./pages/general"));
const Staff = lazy(() => import("./pages/staff"));
const StaffRoles = lazy(() => import("./pages/staff/StaffRoles"));
const StaffAudit = lazy(() => import("./pages/staff/StaffAudit"));
const StaffSchedules = lazy(() => import("./pages/staff/StaffSchedules"));
const Simulator = lazy(() => import("./pages/simulator"));
const CostExplorer = lazy(() => import("./pages/costExplorer"));
const Security = lazy(() => import("./pages/security"));

const theme = createTheme({
  palette: {
    primary: red,
  },
});

function App() {
  return (
    <LocalizationProvider
      dateAdapter={AdapterDayjs}
      dateFormats={{ keyboardDate: "DD/MM/YYYY" }}
      adapterLocale={"es-ES"}
    >
      <ThemeProvider theme={theme}>
        <div id="app">
          {/* Routes nest inside one another. Nested route paths build upon
            parent route paths, and nested route elements render inside
            parent route elements. See the note about <Outlet> below. */}
          <Routes>
            <Route path="/" element={<Layout />}>
              {/* <Route path="/home" element={<Home />}> */}
              {/* Using path="*"" means "match anything", so this route acts like a
          catch-all for URLs that we don't have explicit routes for. */}
              <Route path="" element={<Login />} />
              <Route path="home" element={<Home />} />
              <Route
                path="simulator"
                element={
                  <Suspense fallback={<></>}>
                    <Simulator />
                  </Suspense>
                }
              />
              <Route
                path="cost-explorer"
                element={
                  <Suspense fallback={<></>}>
                    <CostExplorer />
                  </Suspense>
                }
              />
              <Route
                path="sales"
                element={
                  <Suspense fallback={<></>}>
                    <Sales />
                  </Suspense>
                }
              />
              <Route
                path="stats"
                element={
                  <Suspense fallback={<></>}>
                    <Stats />
                  </Suspense>
                }
              />
              <Route
                path="staff"
                element={
                  <Suspense fallback={<></>}>
                    <Staff />
                  </Suspense>
                }
              />
              <Route
                path="staff/roles"
                element={
                  <Suspense fallback={<></>}>
                    <StaffRoles />
                  </Suspense>
                }
              />
              <Route
                path="staff/audit"
                element={
                  <Suspense fallback={<></>}>
                    <StaffAudit />
                  </Suspense>
                }
              />
              <Route
                path="staff/schedules"
                element={
                  <Suspense fallback={<></>}>
                    <StaffSchedules />
                  </Suspense>
                }
              />
              <Route
                path="dashboard"
                element={
                  <Suspense fallback={<></>}>
                    <General />
                  </Suspense>
                }
              />
              <Route
                path="security"
                element={
                  <Suspense fallback={<></>}>
                    <Security />
                  </Suspense>
                }
              />
              <Route
                index
                path="*"
                element={
                  <PageTemplate id="empty" title="404 - Página no encontrada" />
                }
              />
            </Route>
          </Routes>
        </div>
      </ThemeProvider>
    </LocalizationProvider>
  );
}

function Layout() {
  return <ResponsiveAppBar children={<Outlet />} />;
}

export default App;
