import { getSessionToken } from "../business/ParametersController";

const API_VERSION = "v1/business/";
const VPS_PRO_API = "https://api.vps.fooddynamic.es/api/";
const VPS_PRE_API = "https://preapi.vps.fooddynamic.es/api/";
export const API_ENDPOINT = VPS_PRO_API + API_VERSION;

export const enum RESOURCES {
  audits = "audits",
  auth = "auth",
  backtests = "backtests",
  categories = "categories",
  costExplorer = "cost-explorer",
  dashboard = "dashboard",
  products = "products",
  restaurants = "restaurants",
  soldProducts = "sold-products",
  shifts = "shifts",
  stats = "stats",
  users = "users",
  userRoles = "user-roles",
  pin = "pin",
}

export const enum METHODS {
  GET = "GET",
  POST = "POST",
  DELETE = "DELETE",
  PUT = "PUT",
}

export const enum HTTP_R_CODE {
  OK = 200,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  SERVER_ERROR = 500,
}

export interface FetchResult<T> {
  status: HTTP_R_CODE;
  message: string;
  data: T;
}

export const JSON_HEADERS = {
  Accept: "application/json",
  "Content-Type": "application/json",
};

export const securedHeaders = () => {
  return { ...JSON_HEADERS, "FDS-Auth": getSessionToken() };
};

export const URIBuilder = (...args: string[]) => {
  let URI = API_ENDPOINT;
  const length = args.length;
  args.forEach((res, i) => {
    URI += res;
    if (length > 1 && i < length - 1) URI += "/";
  });

  return URI;
};

export const QueryParamInjector = (
  key: string,
  value: any,
  hideIfEmpty = true
) => {
  if (
    hideIfEmpty &&
    (value == undefined || value == null || value == "" || value == "undefined")
  )
    return "";

  return `${key}=${value}`;
};
